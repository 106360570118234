<template>
    <div>
        初始化
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>